import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { IconButton, ListItem, Menu, Drawer, Divider, IconButtonProps, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { OptionType } from '@askporter/utils';
import { Typography } from '../Typography';

export interface OptionsParams {
  /**
   * Title of the menu
   */
  title: string;
  /**
   * Array of options to display in the menu
   */
  options: OptionType<unknown, SxProps>[];
}

export interface OptionsDropdownProps {
  isSmallDevice: boolean;
  optionsParams: OptionsParams;
  iconStyles?: SxProps;
  iconProps?: IconButtonProps;
  dataTestIdIconBtn?: string;
  elementBelowTitle?: JSX.Element | JSX.Element[];
  iconButtonAriaLabel?: string;
  ellipsisIcon?: React.ReactNode;
}

/**
 * The options dropdown component / ellipsis menu which is shown on each linked list item
 */
export const OptionsDropdown: React.FC<React.PropsWithChildren<OptionsDropdownProps>> = ({
  isSmallDevice,
  optionsParams,
  iconStyles,
  iconProps,
  dataTestIdIconBtn = 'ellipsis-button',
  elementBelowTitle = undefined,
  iconButtonAriaLabel,
  ellipsisIcon = <MoreHoriz />,
}: OptionsDropdownProps) => {
  const { title, options } = optionsParams;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'options-dropdown' : undefined;
  const iconSx = { '& > .MuiSvgIcon-root': { bgcolor: open ? 'grey.400' : 'transparent' } };

  const list = options.map((option, idx) => {
    const { name, icon, handleClick, dataTestId = `option-${idx}` } = option;
    const optionalStyles = {
      ...(option?.isDangerStyle && {
        color: 'error.dark',
        // applying colours to an SVG directly like "error.dark" does not work, so need to access the colour via theme.
        '& svg > g': { fill: theme.palette.error.dark },
        '&:hover': {
          backgroundColor: 'error.main',
          color: 'common.white',
          '&& svg > g': { fill: theme.palette.common.white },
        },
      }),
      ...option?.listItemSx,
    };

    return (
      option && (
        <ListItem
          key={`option-${idx}`}
          sx={{
            display: 'flex',
            gap: isSmallDevice ? 8 : 16,
            justifyContent: 'space-between',
            paddingY: 4,
            paddingX: 8,
            ...optionalStyles,
          }}
          onClick={() => {
            if (handleClick) {
              handleClick();
            }
            if (isSmallDevice) {
              setIsDrawerOpen(false);
            } else {
              handleClose();
            }
          }}
          disabled={!handleClick} // with no function provided, the button provides no action so mark as disabled
          data-testid={dataTestId}
        >
          <Typography>{name}</Typography>
          {icon}
        </ListItem>
      )
    );
  });

  if (options.every((option) => !option.name)) {
    return null;
  }

  if (isSmallDevice) {
    return (
      <div data-testid="options-dropdown">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setIsDrawerOpen(true);
          }}
          data-testid={dataTestIdIconBtn}
          aria-describedby={id}
          aria-label={iconButtonAriaLabel}
          sx={{ ...iconSx, ...iconStyles }}
          {...iconProps}
        >
          {ellipsisIcon}
        </IconButton>
        <Drawer
          data-testid="options-menu"
          anchor={'bottom'}
          open={isDrawerOpen}
          onClose={(e: React.SyntheticEvent) => {
            if (typeof e?.stopPropagation === 'function') e.stopPropagation();
            setIsDrawerOpen(false);
          }}
          PaperProps={{
            style: {
              paddingTop: 4,
              paddingBottom: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            },
          }}
        >
          {title && <Typography sx={{ paddingY: 4, paddingX: 8, fontWeight: 'bold' }}>{title}</Typography>}
          {elementBelowTitle}
          <Divider />
          {list}
        </Drawer>
      </div>
    );
  }

  return (
    <div data-testid="options-dropdown">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleOpen(e);
        }}
        data-testid={dataTestIdIconBtn}
        aria-describedby={id}
        aria-label={iconButtonAriaLabel}
        sx={{ ...iconSx, ...iconStyles }}
        {...iconProps}
      >
        {ellipsisIcon}
      </IconButton>
      <Menu
        data-testid="options-menu"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e: React.SyntheticEvent) => {
          if (typeof e?.stopPropagation === 'function') e.stopPropagation();
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {list}
      </Menu>
    </div>
  );
};
