import React, { ReactElement } from 'react';
import { Box, ListItemButton, ListItemIcon, ListItemText, Divider, Alert } from '@mui/material';
import { useNavigate, useMatch } from 'react-router-dom';
import { formatNumber } from '@askporter/utils';
import { Typography } from '../Typography';

export interface NavigationButtonProps {
  /**
   * The icon to show in the button
   */
  icon: ReactElement;
  /**
   * The text to show in the button
   */
  title: string;
  /**
   * Any additional details text to show
   */
  details?: string;
  /**
   * Whether to show an additional section at the bottom styled as a notification
   */
  notification?: { text: string };
  /**
   * A component to render right after the title and count
   */
  indicator?: JSX.Element | JSX.Element[];
  /**
   * A formatted count that shows right after the title e.g. (1,873)
   */
  count?: number;
  /**
   * Page to navigate to when button is clicked
   */
  page: string;
  /**
   * The uid of the entity to navigate to
   */
  uid?: string;
  /**
   * The view to navigate to
   */
  view: string;
  /**
   * Separators to show between the button and other buttons
   */
  dividers?: {
    top?: boolean;
    bottom?: boolean;
  };
  /**
   * A custom test id used by tests to identify the button
   */
  testId?: string;
  /**
   * If it needs to wrap any children components
   */
  children?: React.ReactNode;
  /**
   * Whether to show the button as selected when there is a route match
   */
  highlightExact?: boolean;
  extraDetails?: string;
}

/**
 * Used to render navigation buttons in the left side panel on the Task/Asset/People details pages
 */
export const NavigationButton: React.FC<React.PropsWithChildren<NavigationButtonProps>> = ({
  icon,
  title,
  details,
  notification,
  count,
  page,
  uid,
  view,
  dividers,
  testId,
  indicator,
  children,
  highlightExact = true,
  extraDetails,
}: NavigationButtonProps) => {
  const path = `/app/${page}${uid ? `/${uid}` : ''}${view ? `/${view}` : ''}`;

  const navigate = useNavigate();
  const isSelected = useMatch({ path, end: highlightExact });

  const handleClick = () => {
    window.scrollTo({ top: 0 });
    navigate(path);
  };

  const dataTestId = `navigation-button${page ? `-${page}` : ''}${view ? `-${view}` : ''}`;

  return (
    <>
      {dividers?.top && <Divider />}
      <ListItemButton onClick={() => handleClick()} data-testid={dataTestId} selected={!!isSelected}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gridGap: '4px',
            gridTemplateRows: details && '1fr 1fr',
            alignItems: 'center',
          }}
          data-testid={testId}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText sx={{ mb: details ? 0 : 1 }}>
            <Box sx={{ display: indicator ? 'flex' : undefined, alignItems: 'center' }}>
              <Typography>{`${title}${count ? ` (${formatNumber(count)})` : ''}`}</Typography>
              {!!indicator && indicator}
            </Box>
          </ListItemText>
          {details && (
            <ListItemText sx={{ gridColumn: '2/3', mt: 0 }} data-testid={`${dataTestId}-details`}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: 'text.secondary',
                }}
              >
                {details}
              </Typography>
            </ListItemText>
          )}
          {extraDetails && (
            <ListItemText sx={{ gridColumn: '2/3', mt: 0 }} data-testid={`${dataTestId}-extra-details`}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: 'text.secondary',
                }}
              >
                {extraDetails}
              </Typography>
            </ListItemText>
          )}
        </Box>
      </ListItemButton>
      {notification && (
        <Alert severity="error" data-testid={`${dataTestId}-notification`}>
          {notification.text}
        </Alert>
      )}
      {children && <Box sx={{ px: 4 }}>{children}</Box>}
      {dividers?.bottom && <Divider />}
    </>
  );
};
