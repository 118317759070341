import React, { useState } from 'react';
import { useQueries } from 'react-query';
import { UserSearchResultExternal } from '@askporter/client-grieg-lyric';
import { API } from '../../api';
import { UserAutocompleteProps, UserAutocomplete } from '.';

export interface ManagedStateMultipleUserAutocompleteProps
  extends Omit<Extract<UserAutocompleteProps, { isMultiple: true }>, 'value' | 'isMultiple'> {
  selectedUserUids: string[];
}

/**
 * A UserAutocomplete component with its own managed state
 * it fetches the selected users on page load, using selectedUserUids array
 */
export const ManagedStateMultipleUserAutocomplete: React.FC<ManagedStateMultipleUserAutocompleteProps> = ({
  selectedUserUids = [],
  ...props
}) => {
  const [selectedUsers, setSelectedUsers] = useState<UserSearchResultExternal[]>([]);

  useQueries(
    selectedUserUids.map((uid) => ({
      queryKey: ['get-user-on-page-load', { selectedUserUid: uid }],
      queryFn: () => API().get({ path: `users/${uid}` }),
      // We only want to make the request on page load as the autocomplete won't have the users associated with the uids from the url
      enabled: Boolean(selectedUserUids.length !== selectedUsers.length),
      onSuccess: (data: UserSearchResultExternal) => setSelectedUsers((prev) => [...prev, data]),
    })),
  );

  return (
    <UserAutocomplete
      {...props}
      isMultiple
      value={selectedUsers}
      onChange={(value) => {
        setSelectedUsers(value);
        props.onChange(value);
      }}
    />
  );
};
